import moment from 'moment';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { IOrderItemsSelectorState } from '.';
import { PaginatedResponseApi } from '@models/order/items/response';
import { DraftResponseApi } from '@models/order/drafs/response';
import { toQueryParameters } from '@extensions/object';
import { Paginated } from '@models/paginated';
import { CustomerRequest } from '@models/customer';
import { translate } from '@components/i18n';

export type HistoryResponse = IOrderItemsSelectorState;

export interface HistoryTable {
    id: string;
    draftRef: string;
    name: string;
    condition: string;
    type: string;
    total: number;
    lastUpdate: string;
    bonification?: boolean;
    status: string;
    typeId?: string;
    envio?: boolean;
    codigoMoeda: string
}

export interface OrderHistoryProps extends CustomerRequest {
    status?: string;
    dataInicio?: string;
    dataFim?: string;
    origem?: string
    valorOrigem?: string
}

export const fetchHistory = createAsyncThunk<
    Paginated<Partial<HistoryResponse>>,
    OrderHistoryProps
>('history/fetchHistory', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { history } = state;
        const { request: oldRequest } = history;

        if (oldRequest) {
            const { company, filterValue, filterBy, dataFim, dataInicio } =
                oldRequest;
            if (
                company != request.company ||
                filterValue != request.filterValue ||
                filterBy != request.filterBy ||
                dataFim != request.dataFim ||
                dataInicio != request.dataInicio
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateHistoryRequest(request));

        const params = toQueryParameters({
            companhiaCodigo: request.company,
            filterValue: request.filterValue,
            dataInicio: request.dataInicio,
            dataFim: request.dataFim,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            status: request.status?.toUpperCase()
                ? request.status?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: 'id,desc',
            origem: request.origem,
            valorOrigem: request.valorOrigem
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(`/pedido/list/historico?${params}`);

        const { data, status } = response;

        if (status === 200) {
            const result = response.data.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );

            return {
                items: result.map((d) => d.toOrderState()),
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IHistoryState {
    history: HistoryResponse[];
    request: OrderHistoryProps;
}

const initialState: IHistoryState = {
    history: [],
    request: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
};

const historySlice = createSlice({
    name: 'historySlice',
    initialState,
    reducers: {
        updateHistoryRequest: (
            state,
            action: PayloadAction<OrderHistoryProps>,
        ) => {
            state.request = action.payload;

            return state;
        },
    },
    extraReducers: {
        [fetchHistory.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<HistoryResponse>>,
        ) => {
            state.history = payload.items;
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
    },
});

export const historySelector = (state: RootState): IHistoryState =>
    state.history;

export const historyValues = (state: RootState): HistoryTable[] =>
    state.history.history.map<HistoryTable>((d) => ({
        id: `${d.draftRef}`,
        condition: d.order.paymentCondition
            ? d.order.paymentCondition.description
            : translate('general.notSelected'),
        draftRef: d.order.date
            ? moment(d.order.date).format('DD/MM/YYYY')
            : translate('general.notInformed'),
        lastUpdate: moment(d.lastUpdate).format('DD/MM/YYYY'),
        name: `${d.order.customer.code} - ${d.order.customer.name}`,
        total: d.order.valorPedido,
        customerCity: `${d.order.customer.city} - ${d.order.customer.state}`,
        type: d.order.orderType.type.description,
        typeId: `${d.order.orderType.type.id}`,
        envio: d.order.sendCopy.envioProximoPedidoVenda,
        bonification: d.order.orderType.type.id.toString() !== 'S1',
        hasBonification: d.order.bonificationId
            ? translate('general.yes').toLocaleUpperCase()
            : translate('general.no').toLocaleUpperCase(),
        status: d.order.status,
        protocol: d.order.protocolo,
        codigoMoeda: d.order.codigoMoeda
    }));

export const { updateHistoryRequest } = historySlice.actions;
export default historySlice.reducer;
