import { DownloadOutlined } from '@ant-design/icons';
import { SearchGroup } from '@components/input';
import {
    filterItems,
    IFilterSelectItems,
    orderItemsSelector,
} from '@redux/slices/order';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { exportItemsColumns, filterOptionsTranspo } from '../constants';
import { CustomerCollapse } from './customer-collapse';
import { SelectItemsFilters } from './select-items-filters';
import { SelectItemsWarning } from './select-items-warning';
import { SolidBackground } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { ExportButton } from '@styles/components/button';
import { UserInfoCollapse } from './user-info-collapse';
import { utils, writeFile } from 'xlsx';
import '@extensions/number';
import moment from 'moment';
import { SelectCampaign } from './select-campaigns';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

interface SelectItemsHeaderProps {
    readOnly?: boolean;
    marketing?: boolean;
    bonification?: boolean;
}

export const SelectItemsHeaderFooter: React.FC<SelectItemsHeaderProps> = memo(
    ({ bonification = false }) => {
        const dispatch = useDispatch();
        const selectItemsState = useSelector(orderItemsSelector);

        const filter = (filter: Partial<IFilterSelectItems>): void => {
            dispatch(filterItems(filter));
        };

        function exportToExcel(): void {
            let htmls = `<table id="tbl_exporttable_to_xls" border="1">
            <thead>
                <th>${translate('columns.date')}: ${moment().format('DD-MM-YYYY')}</th>
                <th>${translate('columns.branch')}: ${selectItemsState.order.orderType.platform.description
                }</th>
            </thead>
             <thead>
                <th>${translate('columns.code')}: ${selectItemsState.order.customer.code
                }</th>
                <th>${translate('columns.client')}: ${selectItemsState.order.customer.name
                }</th>
            </thead>
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>${translate('columns.orderAmount')}:</th>
              <th>${formatMoney(selectItemsState?.selectedMoeda?.simbolo, selectItemsState.order.valorPedido)}</th>
            </thead>
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </thead>
            <thead>
                <th>Itens</th>
                <th>${translate('columns.code')}</th>
                <th>${translate('columns.presentation')}</th>
                <th>${translate('columns.qtd')}</th>
                <th>${translate('columns.presentationQtd')}</th>
                <th>${translate('columns.amountQtd')}</th>
                <th>${translate('columns.price')}</th>
                <th>${translate('columns.promo')} %</th>
                <th>${translate('columns.desc')} %</th>
                <th>${translate('columns.subTotal')} %</th>
            </thead><tbody>`;

            const items = exportItemsColumns(selectItemsState.availableItems);

            for (const item of items) {
                htmls += `<tr>
                            <td>${item.Item}</td>
                            <td>${item.Cod}</td>
                            <td>${item.Apresentacao}</td>
                            <td>${item.Quantity}</td>
                            <td>${item.QtdApres}</td>
                            <td>${item.QtdTotal}</td>
                            <td>${item.Preco}</td>
                            <td>${item.Promo}</td>
                            <td>${item.Desconto}</td>
                            <td>${item.Subtotal}</td>
                        </tr>`;
            }

            htmls += `</tbody></table>`;

            document.getElementById('export-div').innerHTML = htmls;

            const fileName = selectItemsState.draftRef
                ? `${translate('general.saleOrder')} - ${selectItemsState.draftRef
                }`
                : translate('general.saleOrder');

            const elt = document.getElementById('tbl_exporttable_to_xls');
            const wb = utils.table_to_book(elt);

            writeFile(wb, `${fileName}.` + 'xlsx');
        }

        return (
            <>
                <Row style={{ marginTop: 20, paddingLeft: 16, paddingRight: 16, }} align="middle" justify='center'>
                    <Col xs={24}>
                        <Row justify={'space-between'} align="middle">
                            <Col xs={24} xl={8} style={{ marginTop: 8 }}>
                                <SearchGroup
                                    onChangeSelect={(v) =>
                                        filter({ filterBy: v })
                                    }
                                    options={filterOptionsTranspo}
                                    allowClear
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    onSearch={(e) => {
                                        filter({ filterValue: e });
                                    }}
                                    onChange={(e) => {
                                        filter({
                                            filterValue: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={24} xl={16} style={{ marginTop: 8 }}>
                                <Row justify='end' style={{ gap: 8 }}>
                                    <Col flex={1} xl={16}>
                                        <SelectItemsWarning />
                                    </Col>
                                    <Col>
                                        <ExportButton
                                            style={{ marginLeft: 0 }}
                                            onClick={exportToExcel}
                                        >
                                            {translate('general.export')}{' '}
                                            <DownloadOutlined />
                                        </ExportButton>
                                    </Col>
                                    <SelectCampaign bonification={bonification} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div
                    style={{
                        visibility: 'hidden',
                        width: 1,
                        height: 1,
                        overflow: 'hidden',
                    }}
                    id={'export-div'}
                ></div>
            </>
        );
    },
);
