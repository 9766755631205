/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { Table as STable } from '@styles/components/table';
import { tableLocale } from './locale';
import {
    FilterValue,
    GetRowKey,
    SorterResult,
    TablePaginationConfig,
    TableRowSelection,
} from 'antd/lib/table/interface';
import { RequestPaginated } from '@models/requestPaginated';
import { Form, FormInstance, Row, TableProps } from 'antd';
import { TableFooter } from './components/footer';
import { translate } from '@components/i18n';

interface IColumn {
    title: string;
    dataIndex: string;
    sorter?: boolean | any;
    width?: string;
}

interface ITableProps {
    id?: string;
    columns: IColumn[];
    pointer?: boolean;
    scroll?: TableProps<any>['scroll'];
    width?: string;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => void;
    isLoading?: boolean;
    pagination?: RequestPaginated;
    dataSource: any[];
    rowKey?: (record: any) => string | GetRowKey<any>;
    filters?: React.ReactNode[];
    actions?: React.ReactNode[];
    onFilterChange?: (changedValues: any, values: any) => void;
    footerObject?: React.ReactNode;
    selectedRowIndex?: number;
    rowSelection?: TableRowSelection<any>;
    form?: FormInstance;
    customKey?: string;
    summary?: (data: readonly any[]) => React.ReactNode;
    initialValues?: Object,
    onRowClick?: (
        record: any,
        index: number,
        event: React.MouseEvent<HTMLElement>,
    ) => void;
}

export const Table: React.FC<ITableProps> = memo(
    ({
        id,
        customKey,
        columns,
        onChange,
        isLoading = false,
        pagination,
        dataSource,
        rowKey,
        filters,
        actions,
        onFilterChange,
        onRowClick,
        pointer,
        footerObject,
        scroll,
        selectedRowIndex,
        rowSelection,
        width,
        form,
        summary,
        initialValues
    }) => {
        return (
            <div style={{ width: '100%' }}>
                {((filters && filters.length > 0) ||
                    (actions && actions.length > 0)) && (
                        <Row
                            style={{
                                marginTop: 15,
                                justifyContent: 'space-between',
                            }}
                        >
                            {filters && filters.length > 0 && (
                                <Form
                                    form={form}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="inline"
                                    onValuesChange={onFilterChange}
                                    initialValues={initialValues}
                                    style={{
                                        alignItems: 'flex-end',
                                        width: width ? width : 'inherit',
                                    }}
                                >
                                    {filters.map((v) => (
                                        <>{v}</>
                                    ))}
                                </Form>
                            )}
                            {actions && actions.length > 0 && (
                                <>
                                    {actions.map((v) => (
                                        <>{v}</>
                                    ))}
                                </>
                            )}
                        </Row>
                    )}
                <Row>
                    {isLoading && (
                        <STable
                            id={id}
                            key={customKey}
                            marginTop={15}
                            selectedRowIndex={selectedRowIndex}
                            columns={columns}
                            pointer={pointer}
                            locale={tableLocale}
                            dataSource={dataSource}
                            summary={summary}
                            pagination={
                                !pagination
                                    ? false
                                    : {
                                        ...pagination,
                                        showSizeChanger: true,
                                        locale: {
                                            items_per_page:
                                                translate('general.perPage'),
                                        },
                                    }
                            }
                            loading={isLoading}
                            footer={
                                footerObject
                                    ? () => <>{footerObject}</>
                                    : () => (
                                        <TableFooter
                                            totalItems={dataSource.length}
                                        />
                                    )
                            }
                            scroll={scroll}
                        />
                    )}
                    {!isLoading && (
                        <STable
                            key={customKey}
                            summary={summary}
                            marginTop={15}
                            selectedRowIndex={selectedRowIndex}
                            columns={columns}
                            pointer={pointer}
                            locale={tableLocale}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => {
                                        if (onRowClick) {
                                            onRowClick(record, rowIndex, e);
                                        }
                                    },
                                };
                            }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            rowKey={
                                rowKey ? rowKey : (record: any) => record.id
                            }
                            dataSource={dataSource}
                            pagination={
                                !pagination
                                    ? false
                                    : {
                                        ...pagination,
                                        showSizeChanger: true,
                                        locale: {
                                            items_per_page:
                                                translate('general.perPage'),
                                        },
                                    }
                            }
                            rowSelection={rowSelection}
                            loading={isLoading}
                            onChange={onChange}
                            footer={
                                footerObject
                                    ? () => <>{footerObject}</>
                                    : () => (
                                        <TableFooter
                                            totalItems={pagination?.total || dataSource?.length}
                                        />
                                    )
                            }
                            scroll={scroll}
                        />
                    )}
                </Row>
            </div>
        );
    },
);
