import { GROUP_TYPES } from "src/enums/group-types";

export interface ApprovalGroupRequest {
    descricao: string;
    codigoTipoPedido: string;
    divisao: string;
    aprovadores?: ApproverModel[];
}

export interface ApprovalGroupFilterRequest {
    query?: string;
    tipoPedido?: GROUP_TYPES
}

export interface ApproverModel {
    grupoAprovacaoId: number;
    limite: number;
    limiteBonificacao: number;
    ordem: number;
    usuarioId: number;
    tipo?: string;
}

export class ApprovalGroupRequestAPI {
    constructor(data?: ApprovalGroupRequest) {
        if (data) {
            this.descricao = data.descricao;
            this.codigoTipoPedido = data.codigoTipoPedido;
            this.divisao = data.divisao;
            this.aprovadores = data.aprovadores;
        }
    }

    descricao: string;
    codigoTipoPedido: string;
    divisao: string;
    aprovadores?: ApproverModel[];
}
