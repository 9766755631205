import React, { memo, useContext } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ExportButton } from '@styles/components/button';
import { SearchGroup } from '@components/input';
import { IFilterSelectItems } from '@redux/slices/order';
import { ModalContext } from 'src/contexts/modal-context';
import { EditApprovalGroupModal } from './edit-modal';
import { fetchApprovalGroups, fetchApprovalGroupsFilter, filterApprovalGroup } from '@redux/slices/approval-groups';
import { translate } from '@components/i18n';
import { Option, Select } from '@styles/components/form';
import { groupType } from '../constants';
import { GROUP_TYPES } from 'src/enums/group-types';

export const Header: React.FC = memo(() => {
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);

    const filter = (filter: Partial<IFilterSelectItems>): void => {
        dispatch(filterApprovalGroup(filter.filterValue));
        dispatch(fetchApprovalGroupsFilter({
            query: filter.filterValue || "",
            tipoPedido: filter.tipoPedido
        }));
    };

    const addGroup = (): void => {
        modalContext.setIsOpen(true, <EditApprovalGroupModal />, {
            title: translate('general.addNewGroup'),
            width: '50%',
        });
    };

    return (
        <Col>
            <Row style={{ marginTop: 20 }} align="middle">
                {/* <Col span={1} /> */}
                <Col span={24}>
                    <Row justify={'space-between'} align="middle">
                        <Col span={5}>
                            <label>Buscar:</label>
                            <br />
                            <SearchGroup
                                onBlur={(e) =>
                                    filter({ filterValue: e.target.value })
                                }
                                allowClear
                                placeholder={translate(
                                    'forms.placeholders.searchGroupDivision',
                                )}
                                onSearch={(e) => {
                                    filter({ filterValue: e });
                                }}
                                onChange={(e) => {
                                    filter({ filterValue: e.target.value });
                                }}
                                width='95%'
                            />
                        </Col>
                        <Col span={5}>
                            <label>Tipo de Pedido:</label>
                            <br />
                            <Select
                                placeholder={translate('general.all')}
                                dropdownMatchSelectWidth={false}
                                allowClear
                                onChange={(value) => {
                                    filter({ tipoPedido: value as GROUP_TYPES });
                                }}
                            >
                                {groupType.map((c) => (
                                    <Option value={c.name}>
                                        {c.key}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={14}>
                            <Row justify={'end'}>
                                <Col span={7}></Col>
                                <Col span={5} style={{ marginRight: 10 }}>
                                    <ExportButton onClick={addGroup}>
                                        {translate('forms.buttons.addNew')}
                                    </ExportButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={1} /> */}
            </Row>
        </Col>
    );
});
