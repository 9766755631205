export default {
    translations: {
        pages: {
            home: 'Pedido Eletrônico',
            users: 'Usuários',
            scheduledOrders: 'Pedidos Programados',
            financialDiscountApproved: 'Descontos financeiros aprovados',
            pendingOrders: 'Pedidos Pendentes',
            historyOfOrders: 'Histórico de Pedidos',
            comercialBonus: 'Bonificações Comerciais X Vendas',
            approvedOrders: 'Pedidos aprovados',
            sendOrder: 'Enviar pedidos',
            newOrder: 'Novo Pedido',
            newOrderItems: 'Novo Pedido - Itens',
            searchCliente: 'Buscar cliente',
            searchDistributor: 'Buscar distribudor',
            newOrderBonificatoinMarketing:
                'Novo Pedido - Bonificação Marketing',
            draft: 'Rascunhos',
            newOrderBonification: 'Novo Pedido - Bonificação',
            minOrder: 'Pedido mínimo',
            orderSelection: 'Seleção de pedidos',
            selectClient: 'Buscar cliente',
            historyOfDiscountSolicitations:
                'Histórico de solicitações de desconto',
            approvalsOfFinancialDiscount: 'Aprovações de desconto financeiro',
            approvalDiscount: 'Aprovação de desconto',
            discountSolicitations: 'Solicitação de desconto',
            delegation: 'Delegação',
            settings: 'Configurações',
            campaign: 'Campanha',
            login: 'Acessar conta',
            orders: 'Pedidos',
            orderApprovals: 'Aprovação de pedidos',
            marketingApprovals: 'Aprovadores de Marketing',
            approvalGroup: 'Grupos de aprovação',
            items: 'Itens',
            deadline: 'Prazo',
            itemDeadline: 'Item X Prazos',
            cost: 'Custo',
            semaphore: 'Semáforo',
            route: 'Rotas',
            transferClients: 'Transfer - Clientes',
            transferItems: 'Transfer - Itens',
        },
        titlePages: {
            home: 'Pedido Eletrônico',
            users: 'Usuários',
            scheduledOrders: 'Pedidos Programados',
            financialDiscountApproved: 'Descontos financeiros aprovados',
            pendingOrders: 'Pedidos Pendentes',
            historyOfOrders: 'Histórico de Pedidos',
            comercialBonus: 'Bonificações Comerciais X Vendas',
            approvedOrders: 'Pedidos aprovados',
            sendOrder: 'Enviar pedidos',
            newOrder: 'Novo Pedido',
            newOrderItems: 'Novo Pedido - Itens',
            searchCliente: 'Selecione/Pesquise um cliente',
            searchDistributor: 'Selecione/Pesquise um distribuidor',
            draft: 'Rascunhos',
            minOrder: 'Pedido mínimo',
            orderSelection: 'Seleção de pedidos',
            selectClient: 'Selecione/Pesquise um cliente',
            historyOfDiscountSolicitations: 'Histórico de desconto financeiro',
            approvalsOfFinancialDiscount: 'Aprovações de desconto financeiro',
            delegation: 'Delegação',
            settings: 'Configurações',
            campaign: 'Campanha',
            loginOrder: 'Pedido',
            loginEletronic: 'Eletrônico',
            orders: 'Pedidos',
            marketingApprovals: 'Aprovadores de Marketing',
            approvalGroup: 'Grupos de aprovação',
            items: 'Itens',
            deadline: 'Prazo',
            itemDeadline: 'Item X Prazos',
            cost: 'Custo',
            semaphore: 'Semáforo',
            route: 'Rotas',
            transferClients: 'Transfer - Clientes',
            transferItems: 'Transfer - Itens',
            transferOrder: 'Pedido transfer'
        },
        home: {
            hello: 'Olá',
            welcome: 'Seja bem vindo(a)!',
            cards: {
                new: 'Novo',
                order: 'Pedido',
                orders: 'Pedidos',
                transferOrder: 'Pedido Transfer',
                draft: 'Rascunho',
                scheduled: 'Programado',
                approvals: 'Aprovações',
                reports: 'Relatórios',
                historyOf: 'Histórico de',
                newSolicitationOf: 'Nova solicitação de',
                financialDiscount: 'Desconto financeiro',
                approvalsOf: 'Aprovações de',
                 transferOrders: 'Pedidos Transfer'
            },
        },
        sideMenu: {
            start: 'Início',
            orders: {
                self: 'Pedidos',
                newOrder: 'Novo pedido',
                draftOrders: 'Pedidos rascunhos',
                scheduledOrders: 'Pedidos programados',
                historyOfOrders: 'Histórico de pedidos',
                scheduled: 'Programados',
            },
            approvals: {
                self: 'Aprovações',
                orders: 'Pedidos',
                financialDiscounts: 'Desconto financeiros',
            },
            financialDiscounts: {
                self: 'Descontos financeiros',
                discountSolicitation: 'Solicitação de desconto',
                historyOfDiscount: 'Histórico de descontos',
            },
            registrations: {
                self: 'Cadastros',
                users: 'Usuários',
                approvalGroups: 'Grupos de aprovação',
                marketingApproval: 'Aprovadores de Marketing',
                campaigns: 'Campanhas',
                minOrder: 'Pedido mínimo',
                items: 'Itens',
                route: 'Rotas',
                deadline: 'Prazo',
                itemDeadline: 'Item X Prazos',
                cost: 'Custo',
                semaphore: 'Semáforo',
                transferClients: 'Transfer - Clientes',
                transferItems: 'Transfer - Itens',
            },
            delegation: 'Delegação',
            settings: 'Configurações',
            reports: {
                self: 'Relatórios',
                pendingOrders: 'Pedidos pendentes',
                approvedOrders: 'Pedidos aprovados',
                historyOfOrders: 'Histórico de Pedidos',
                comercialBonus: 'Bonificações Comerciais X Vendas',
                financialDiscountsApproved: 'Descontos financeiros aprovados',
                 historyOfTransferOrders: 'Histórico de pedido transfer'
            },
        },
        forms: {
            rules: {
                login: 'É obrigatório digitar seu login',
                required: 'Campo obrigatório',
                password: 'É obrigatório digitar sua senha',
                name: 'Obrigatório digitar o nome',
                fiveCaracteres: 'Digite pelo menos 5 caracteres',
                division: 'Obrigatório escolher a divisão',
                roles: 'Obrigatório escolher ao menos um papel',
                email: 'Obrigatório digitar o email',
                validEmail: 'Digite um email válido',
                limit: 'Obrigatório digitar o limite',
                len10: 'Campo com no máximo 10 dígitos',
                existLogin: 'Já existe um usuário com este login',
                selectOption: 'Obrigatório escolher uma opção',
                selectUserCopy:
                    'Obrigatório escolher o usuário para copiar os dados',
                transportType: 'Obrigatório escolher o tipo de transporte',
                value: 'Obrigatório digitar o valor',
                delegate: 'Obrigatório escolher o delegado',
                startDate: 'Obrigatório escolher a data de início',
                endDate: 'Obrigatório escolher a data de fim',
                startDateBeforeToday:
                    'Data início não pode ser antes da data de hoje',
                endDateCannotBeBeforeStart:
                    'Data fim não pode ser antes a data início',
                startDateCannotBeAfterEnd: "Data de início não pode ser depois da data final",
                delegateReason: 'Obrigatório escolher o motivo',
                numberItem: 'Obrigatório digitar o número do item',
                campainName: 'Obrigatório digitar o nome da campanha',
                selectedCampain: 'Obrigatório escolher o tipo de campanha',
                field: 'Por favor, selecione um campo',
                operator: 'Obrigatório escolher o operador',
                chooseValue: 'Obrigatório escolher o valor',
                qtd: 'Por favor, digite a quantidade',
                obg: 'Campo obrigatório',
                approvalGroupName:
                    'Obrigatório digitar o nome do grupo de aprovação',
                chooseOrderType: 'Obrigatório escolher o tipo de pedido',
            },
            labels: {
                email: 'Email',
                copyEmail: 'Cópia E-mail',
                login: 'Login',
                password: 'Senha',
                rememberAccess: 'Lembrar acesso',
                recoveryLogin: 'Digite seu login para recuperar a senha',
                fullName: 'Nome completo',
                division: 'Divisão',
                roles: 'Papéis',
                userLimitBonification: 'Limite Usuário Bonificação',
                userLimit: 'Limite do Usuário',
                approvalGroupOfSales: 'Grupo de Aprovação Vendas',
                approvalGroupOfDiscounts: 'Grupo de aprovação de descontos',
                marketingApprover: 'Aprovador de Marketing',
                specifyItems: 'Itens Específicos:',
                jdeCode: 'Código JDE',
                ignoreDailyEmailScheduledOrders:
                    'Ignorar e-mail diário de Pedidos Programados',
                ignoreDailyEmailApprovals:
                    'Ignorar e-mail diário de Aprovações',
                lineManager: 'Gerente de linha?',
                active: 'Ativo',
                line: 'Linha',
                showDivisionClients: 'Exibir clientes divisão',
                chooseUserCopy: 'Escolha o usuário para copiar os dados',
                searchFor: 'Pesquisar por',
                searchType: 'Tipo',
                cia: 'CIA',
                type: 'Tipo',
                month: 'Mês',
                year: 'Ano',
                date: 'Data',
                status: 'Status',
                scheduleOrder: 'Programar Pedido',
                orderType: 'Tipo de pedido',
                originPlatform: 'Plataforma de origem',
                moneyType: 'Moeda',
                client: 'Cliente',
                cnpj: 'CNPJ',
                sendOrderWithNextSale:
                    'Enviar pedido junto do próximo pedido de venda',
                sendCopyFor: 'Enviar cópia para',
                observation: 'Observação',
                selectDiscountValue: 'Selecione o valor do desconto',
                paymentCondition: 'Condição de pagamento',
                buyOrder: 'Ordem compra',
                deliveryDate: 'Data da entrega',
                scheduledDate: 'Data programada',
                transportCode: 'Código tipo de transporte',
                transportType: 'Tipo de Transporte',
                validation: 'Validação',
                clientType: 'Tipos de Cliente',
                origin: 'Origem',
                destiny: 'Destino',
                minValue: 'Valor mínimo',
                totalOrdersValues: 'Valor total pedidos',
                totalValueWithDiscount: 'Valor total com desconto',
                valueDiscount: 'Valor do desconto',
                financialDiscount: 'Desconto financeiro',
                someObservation: 'Alguma observação?',
                selection: 'Seleção',
                filterBySituation: 'Filtrar por situação',
                whyYouAreRefusingThisOrder:
                    'Digite o porque você está recusando este pedido',
                results: 'Resultados',
                field: 'Campo',
                numberItem: 'Número Item',
                operator: 'Operador',
                value: 'Valor',
                qtd: 'Quantidade',
                exclusive: 'Exclusiva',
                presentation: 'Apresentação',
                name: 'Nome',
                color: 'Cor',
                percent: 'Percentual',
                compkit: 'CompKit',
                controlled: 'Controlado',
                agreement100: 'Convênio 100',
                etrade: 'ETrade',
                ipi: 'IPI',
                kit: 'Kit',
                limited: 'Limitado',
                multiply: 'Múltiplo',
                ipiValue: 'Valor IPI',
                number: 'Número',
                branch: 'Filial',
                icmsCredit: 'Crédito ICMS',
                code: 'Código',
                description: 'Descrição',
                minOrder: 'Pedido mínimo',
                branchCode: 'Código da Filial',
                campainCode: 'Código da Campanha',
                state: 'Estado',
                reportPermission: "Permissão de relatórios",
                category: 'Categoria',
                vigency: 'Vigência',
                price: 'Preço',
                distributor: 'Distribuidor',
                address: 'Endereço',
                address1: 'Endereço 1',
                address2: 'Endereço 2',
                neighborhood: 'Bairro',
                city: 'Cidade',
                promotor: 'Promotor',
            },
            placeholders: {
                login: 'Digite o seu login',
                password: 'Digite a sua senha',
                photo: 'Foto',
                searchGroup: 'Pesquise por nome, email ...',
                searchGroupDivision: 'Pesquise por nome, divisão ...',
                line: 'Seleciona a linha',
                chooseFilter: 'Escolha um filtro',
                search: 'Pesquisar',
                startDate: 'Data Início',
                endDate: 'Data Fim',
                chooseDate: 'Escolha uma data (opcional)',
                chooseOption: 'Escolha uma opção',
                email: 'Email',
                writeTheData: 'Informe os dados',
                chooseTheDate: 'Escolha a data',
                comment: 'Digite seu comentário',
                letReason: 'Digite o motivo. Min: 5 caracteres',
                searchForReason: 'Pesquise pelo motivo',
                writeValue: 'Digite o valor',
                chooseApprover: 'Escolha um aprovador',
                searchByName: 'Pesquise pelo nome',
                selectType: 'Por favor, selecione um tipo',
                selectApprover: 'Por favor, selecione um aprovador',
                selectPermission: 'Por favor, selecione uma permissão',
            },
            buttons: {
                access: 'Acessar',
                forgotPasswordQuestion: 'Esqueceu a senha?',
                copyUser: 'Copiar de outro usuário',
                addNew: 'Adicionar Novo',
                addNewF: 'Adicionar Nova',
                search: 'Buscar',
                changeClient: 'Alterar cliente',
                continue: 'Prosseguir',
                apply: 'Aplicar',
                addValidation: 'Adicionar validação',
                requestDiscount: 'Solicitar Desconto',
                discountApprove: 'Aprovar Desconto',
                toApprove: 'Aprovar',
                toRefuse: 'Recusar',
                update: 'Atualizar',
                endCampain: 'Encerrar campanha(s)',
                add: 'Adicionar',
                addCondition: 'Adicionar condição',
                copyOrder: 'Copiar pedido',
                toRepprove: 'Reprovar',
                addApprover: 'Adicionar Aprovador',
                campaigns: 'Campanhas',
            },
        },
        general: {
            recoveryAccess: 'Recuperar acesso',
            verifyingAccess: 'Verificando acesso...',
            registeringUser: 'Cadastrando usuário...',
            registerUser: 'Adicionar novo usuário',
            updatingUser: 'Atualizando usuário...',
            editUser: 'Editar usuário',
            adm: 'Administrador',
            approver: 'Aprovador',
            approved: 'Aprovado',
            pending: 'Pendente',
            reproved: 'Reprovado',
            integrated: 'Integrado',
            billed: 'Faturado',
            delivered: 'Entregue',
            representer: 'Representante',
            billing: 'Faturamento',
            controllership: 'Controladoria',
            financial: 'Financeiro',
            all: 'Todos',
            delete: 'Deletar',
            export: 'Exportar',
            messageDelete: 'Tem certeza que deseja excluir?',
            yes: 'Sim',
            no: 'Não',
            ok: 'Ok',
            cancel: 'Cancelar',
            loginAs: 'Logar como',
            errorT: 'Erro!',
            logoutLogin:
                'Você será deslogado e será logado como este usuário. Tem certeza que deseja continuar?',
            perPage: 'por página',
            totalItems: 'Total de itens:',
            newOrder: 'Novo pedido',
            orderOnHold: 'Pedidos em aguardo',
            yourOrdersZero: 'Você tem 0 novos pedidos',
            yourOrdersTwenty: 'Você tem 20 pedidos em aguardo',
            editPersonalData: 'Editar dados pessoais',
            myData: 'Meus Dados',
            division: 'Divisão',
            logout: 'Sair',
            send: 'Enviar',
            login: 'Login',
            copyright: 'Copyright © 2022. Todos os direitos reservados',
            sendOrderWithoutBonification:
                'Você está enviando pedidos sem bonificação. Tem certeza que deseja enviar?',
            orderSelectedModalFirstPart: ` Os pedidos selecionados (e
                    bonificações eventualmente
                    relacionadas)`,
            orderSelectedModalSecondPart: `serão excluídos
            definitivamente. Deseja prosseguir?`,
            sendingOrders: 'Enviando pedidos...',
            resendedEmail: 'Email reenviado!',
            resendedEmailError: 'Ocorreu um erro ao enviar email do pedido!',
            success: 'Sucesso!',
            error: 'Ocorreu um erro, tente novamente mais tarde!',
            filialAvailableError:
                'Não existem filiais disponíveis para este tipo de pedido.',
            chooseApprover: 'Escolher aprovador',
            wantChooseApprover: 'Deseja escolher o aprovador?',
            advance: 'Avançar',
            ipiTax: `Quando aplicável, itens com IPI terão o valor do
            imposto acrescido no subtotal.`,
            activatedEarlyDiscount: 'Desconto por Antecipação Ativado',
            saleOrder: 'Pedido de venda',
            cannotBeEdited: 'Resultado - não pode ser editado',
            activeCampains: 'Campanhas ativas',
            orderAmout: 'Total dos pedidos',
            emptyList: 'Nenhum registro encontrado',
            cancelSorting: 'Clique para cancelar a ordenação',
            ascSort: 'clique para ordenar crescente',
            descSort: 'clique para ordenar decrescente',
            colapseLine: 'Colapsar linha',
            expandLine: 'Expandir linha',
            sort: 'Ordenar',
            selectAllData: 'Selecionar todos os dados',
            filterTitle: 'Filter menu',
            filterConfirm: 'OK',
            filterReset: 'Resetar',
            filterEmptyText: 'Sem filtros',
            selectAll: 'Selecionar a página corrente',
            selectInvert: 'Inverter seleção',
            sendingMarketingBonitication:
                'Enviando bonificação de marketing...',
            wantCreateBonificationForThisOrder:
                'Deseja criar uma bonificação para este pedido?',
            comercialBonification: 'Bonificação comercial',
            sendingOrder: 'Enviando pedido...',
            savingDraft: 'Salvando rascunho...',
            edition: 'edição',
            creation: 'criação',
            wantExitOf: 'Tem certeza que deseja sair da',
            toOrder: 'do pedido?',
            toprogramming: 'da programação?',
            sendOrderWithNextSale:
                'Enviar pedido junto do próximo pedido de venda',
            noDetectedApprover: `Nenhum aprovador
            detectado para este
            pedido`,
            approvers: 'Aprovadores',
            saveDraft: 'Salvar Rascunho',
            editBonitication: 'Editar bonificação',
            wantToCancel: 'Deseja cancelar a',
            wantToCancelBonificationAndRetorn:
                'Deseja mesmo cancelar a bonificação e retornar a tela do pedido de venda?',
            verifyingOtherOrders: 'Verificando outros pedidos',
            maybeWantBonify: 'Que você talvez queira bonificar',
            noOtherOrderFind: 'Nenhum outro pedido encontrado',
            wantToAddOtherOrderToBonification:
                'Deseja adicionar mais pedidos para bonificação?',
            deliveryClient: 'Cliente entrega',
            changePrice: 'Alterar preço',
            loading: 'Carregando...',
            newPrice: 'Novo preço',
            in: 'em',
            lasPriceWas: 'O último preço praticado foi',
            noHistoryForThisItem: 'Nenhum histórico pra este item encontrado',
            newValue: 'novo valor',
            actualOrder: 'Pedido atual',
            suframaDiscount: 'Desconto Suframa',
            tradeValue: 'Valor trade',
            suggestionNotFound:
                'Não foi possível encontrar sugestão de o prazo de pagamento, escolha um antes de salvar o pedido',
            addNewMinOrder: 'Adicionar novo pedido mínimo',
            editMinOrder: 'Editar pedido mínimo',
            registeringNewMinOrder: 'Cadastrando novo pedido mínimo...',
            updatingNewMinOrder: 'Atualizando pedido mínimo...',
            minOrderRemovedSuccess: 'Pedido mínimo removido com sucesso',
            validations: 'Validações',
            exceptions: 'Exceções',
            existValidationsWithThisConditions:
                'Já existe uma validação com essas condições',
            requestDiscount: 'Solicitar desconto',
            orders: 'pedido(s)',
            selectingDiscountYouSure:
                'Você está selecionando pedidos para desconto. Tem certeza que deseja continuar?',
            discount: 'Desconto',
            order: 'Pedido',
            activeCampain: `Campanhas
            ativas`,
            notInformed: 'Não informado',
            wating: 'aguardando',
            comentary: 'Comentário',
            noComment: 'Nenhum comentário',
            removeComment: 'Remover comentário',
            lostCommentYouSure:
                'Você perderá esse comentário. Tem certeza que deseja excluir?',
            goBackList: 'Voltar para listagem',
            whatRefuse: 'Qual o motivo da recusa?',
            letObservation: 'Se desejar, deixa uma observação',
            ordersT: 'Pedidos',
            iAndMyDelegations: 'Eu e minhas delegações',
            i: 'Eu',
            myPendingApprovals: 'Minhas aprova. pendentes',
            waitingApproval: 'Aguard. aprovadores anteriores',
            addNewDelegation: 'Adicionar nova Delegação',
            updatingApprovalGroup: 'Atualizando Grupo de Aprovação...',
            registeringApprovalGroup: 'Cadastrando Grupo de aprovação...',
            editDelegation: 'Editar Delegação',
            existDelegation: 'Já existe uma delegação',
            selectedPeriod: 'para o período escolhido',
            updatingParameter: 'Atualizando parâmetro...',
            editParameter: 'Editar Parametro',
            addNewCampain: 'Adicionar nova campanha',
            editCampain: 'Editar campanha',
            endCampain: 'Campanha(s) finalizadas!',
            errorOnEndCampain: 'Ocorreu um erro ao finalizar campanhas',
            activeCampainsSelected: 'As campanhas ativas selecionadas',
            activeCampainsSelected2Part: `serão encerradas
            imediatamente. Deseja prosseguir?`,
            updatingCampain: 'Atualizando campanha...',
            registeringNewCampain: 'Cadastrando nova campanha...',
            existCampainWithThisName: 'Já existe uma campanha com este nome',
            campainCondition: 'Condições da campanha',
            orderType: 'Tipo de pedido',
            paymentCondition: 'Condição de pagamento',
            commentOfItem: 'Comentários do item',
            query: 'Consulta',
            nextOrder: 'Próximo pedido',
            actualSale: 'Venda Atual',
            weightedDiscount: 'Desconto ponderado',
            copyingOrder: 'Copiando pedido...',
            commentHistory: 'Histórico de Comentários',
            commentSave: 'Comentário salvo com sucesso!',
            errorComment: 'Um erro aconteceu ao adicionar o comentário',
            deleteComment: 'Comentário excluido com sucesso!',
            errorRefuseOrder: 'Ocorreu um erro ao recusar o pedido!',
            refusedOrder: 'Pedido recusado!',
            acceptOrder: 'Pedido aceito!',
            errorAcceptOrder: 'Ocorreu um erro ao aceitar o pedido!',
            solicitationDiscount: 'Desconto solicitado!',
            errorSolicitationDiscount:
                'Ocorreu um erro ao solicitar desconto dos pedidos!',
            addNewGroup: 'Adicionar novo Grupo',
            editMarketingApprover: 'Editar Aprovador de Marketing',
            marketingBonification: 'Bonificação de Marketing',
            comercialBonificationSale: 'Venda / Bonificação comercial',
            financialDiscounts: 'Descontos Financeiros',
            editApprovalGroup: 'Editar Grupo de Aprovação',
            existApprovalGroupWithThisName:
                'Já existe um grupo de aprovação com este nome',
            errorListApprovalGroup:
                'Ocorreu um erro para listar os Grupos de Aprovação',
            approvalGroupRegistered:
                'Grupo de Aprovação cadastrado com sucesso',
            errorRegisteringUser: 'Um erro aconteceu ao cadastrar o usuário',
            groupUpdated: 'Grupo atualizado com sucesso',
            approvalGroupUpdated: 'Grupo de aprovação atualizado com sucesso',
            errorChangeLogin: 'Um erro aconteceu ao trocar o login',
            userOrPasswordError: 'Usuário ou senha inválidos',
            errorRegisteringCampain:
                'Um erro aconteceu ao cadastrar a campanha',
            campainRemoved: 'Campanha removida com sucesso',
            campainUpdated: 'Campanha atualizada com sucesso',
            errorUpdatingCampain: 'Um erro aconteceu ao atualizar a campanha',
            campainRegistered: 'Campanha cadastrada com sucesso',
            parameterUpdated: 'Parametro atualizado com sucesso',
            errorUpdatingParameter:
                'Um erro aconteceu ao atualizar o parametro',
            errorListingParameter: 'Ocorreu um erro para listar os parametros',
            userUpdated: 'Usuário atualizado com sucesso',
            erroListingUsers: 'Ocorreu um erro para listar os usuários',
            errorListingMinOrders:
                'Ocorreu um erro para listar os pedidos mínimos',
            minOrderRegistered: 'Pedido mínimo cadastrado com sucesso',
            minOrderUpdated: 'Pedido mínimo atualizado com sucesso',
            bonificationOrderUpdated:
                'Pedido de bonificação atualizado com sucesso',
            cannotBeDelete: 'não pode ser excluida no momento',
            errorDelete: 'Falha na exclusão',
            notSelected: 'Não selecionado',
            reportFinacialDiscountApproveds:
                'Relatorio_Descontos_Financeiros_Aprovados',
            reportScheduledOrders: 'Relatorio_Pedidos_Programados',
            orderCreatedAndDraft: 'Pedido criado e salvo em rascunhos',
            orderUpdatedAndDraft: 'Pedido atualizado e salvo em rascunhos',
            orderUpdated: 'Pedido atualizado',
            orderSended: 'Pedido enviado com sucesso',
            minValueTransport:
                'O valor mínimo de um ou mais tipos de transporte não foi atingido:',
            minValueOf: 'Valor mínimo de',
            notReached: 'não atingido.',
            registeredUpdated: 'Usuário cadastrado com sucesso',
            sendedEmailWithNewPassword:
                'Foi enviado um email com a nova senha para o email cadastrado',
            userNotFound: 'Usuário não localizado',
            noHasNecessaryItems: 'Não possui os itens necessários',
            campainsAvailableToOrder: 'Campanhas disponíveis para este pedido',
            reportPendingOrders: 'Relatorio_Pedidos_Pendentes',
            reportApprovedOrders: 'Relatorio_Pedidos_Aprovados',
            reportComercialSalesBonification:
                'Relatorio_Bonificacoes_Comerciais_Vendas',
            reportOrderHistory: 'Relatorio_Historico_Pedidos',
            erroListingItems: 'Ocorreu um erro para listar os itens',
            itemUpdated: 'Item atualizado com sucesso',
            registerItem: 'Adicionar novo item',
            updatingItem: 'Atualizando item...',
            registeringItem: 'Cadastrando item...',
            editItem: 'Editar item',
            registered: 'Cadastrado com sucesso',
            updated: 'Atualizado com sucesso',
            noBaseOfPrice: "Item sem preço base cadastrado",
            discountApprove: 'Aprovar Desconto',
            discountRepprove: 'Reprovar Desconto',
            onlyMyOrders: 'Acesso somente aos seus pedidos',
            onlyMyDivision: 'Acesso somente aos pedidos da BU do qual o usuário estava cadastrado',
            totalAccess: 'Acesso total',
            bonificationOrderSuccess: 'Pedido de bonificação criado com sucesso',
            youChoosedAExclusiveCampain: 'Você selecionou uma campanha exclusiva, outras campanhas selecionadas serão desmarcadas. Tem certeza?',
            choosedExclusiveCampain: 'Campanha exclusiva selecionada',
            youChoosedAExclusiveCampainThatWasChoosedBefore: `Você selecionou uma campanha porém uma campanha exlusiva já está selecionada.
            \nDeseja desmarcar a campanha exclusiva e selecionar esta?`,
            conflictCampain: 'Campanha conflitante',
            youChoosedAConflictCampain: `Você selecionou uma campanha conflitante com a campanha #NAME.
            \nItem conflitante: #ITEM em #WHERE. 
            \nPara selecionar esta campanha, desmarque a campanha "#NAME".`,
            promoter: 'Promotor',
            registerTransferClient: 'Adicionar Cliente Transfer',
            registeringTransferClient: 'Cadastrando novo cliente transfer...',
            updatingTransferClient: 'Atualizando cliente transfer...',
            erroListingClients: 'Ocorreu um erro para listar os clientes',
            erroListingDistributors: 'Ocorreu um erro para listar os distribuidores',
            editClient: 'Editar cliente',
            deletingOrders: "Deletando pedidos...",
            inactive: "Inativo",
            active: "Ativo",
        },
        columns: {
            name: 'Nome',
            jdeCode: 'Código JDE',
            roles: 'Papéis',
            active: 'Ativo',
            login: 'Login',
            division: 'Divisão',
            order: 'Pedido',
            client: 'Cliente',
            condition: 'Condição',
            scheduledDate: 'Data programada',
            orderType: 'Tipo Pedido',
            hasBonification: 'Possui bonificação?',
            total: 'Total',
            solicitation: 'Solicitação',
            clientCode: 'Cód. Cliente',
            representerCode: 'Cód. Representante',
            representer: 'Representante',
            discount: 'Desconto',
            totalWithDiscount: 'Total com desconto',
            seller: 'Vendedor',
            date: 'Data',
            clientName: 'Nome Cliente',
            deadline: 'Prazo',
            totalAmount: 'Valor Total',
            pendency: 'Pendência',
            protocol: 'Protocolo',
            bonusOrder: 'Pedido da Bonificação',
            bonusProtocol: 'Protocolo da Bonificação',
            orderDate: 'Data do pedido',
            bonusTotalAmount: 'Valor Total da Bonificação',
            user: 'User',
            saleOrder: 'Pedido da Venda',
            saleProtocol: 'Protocolo da Venda',
            saleTotalAmount: 'Valor Total da Venda',
            customerCity: 'Cidade do Cliente',
            resendEmail: 'Reenviar email',
            forceSync: 'Envio imediato',
            code: 'Código',
            cia: 'Cia',
            type: 'Tipo',
            cnpj: 'CNPJ',
            city: 'Cidade',
            state: 'Estado',
            country: 'País',
            moneyType: 'Moeda',
            branch: 'Filial',
            orderAmount: 'Total do Pedido',
            item: 'Item',
            items: 'Itens',
            presentation: 'Apresentação',
            qtd: 'Qtd.',
            presentationQtd: 'Qtd. Apres.',
            amountQtd: 'Qtd. Total',
            price: 'Preço',
            promo: 'Promo',
            desc: 'Desc.',
            subTotal: 'Total Parcial',
            sequence: 'Ordem',
            clientType: 'Tipo do Cliente',
            address: 'Endereço',
            transportCode: 'Cód. Transporte',
            addition: 'Acréscimo',
            bonification: 'Bonificação',
            transportType: 'Tipo de transporte',
            validations: 'Validações',
            pandingWith: 'Pendente com',
            approver: 'Aprovador',
            delegate: 'Delegado',
            startDate: 'Data de Início',
            endDate: 'Data de Término',
            reason: 'Motivo',
            delegateReason: 'Delegado Motivo',
            description: 'Descrição',
            value: 'Valor',
            itemQtd: 'Quantidade item',
            paymentCondition: 'Condição de pagamento',
            clientClassification: 'Classificação do Cliente',
            stateOfClient: 'Estado do Cliente',
            finalized: 'Finalizada',
            exclusive: 'Exclusivo',
            exclusiveCampain: 'Campanha exclusiva',
            finalizedCampain: 'Finalizada?',
            actions: 'Ações',
            triangulation: 'Triangulação',
            buyOrder: 'Ordem de compra',
            deliveryDate: 'Data entrega',
            limit: 'Limite',
            profile: 'Perfil',
            bonificationLimite: 'Limite da bonificação',
            userN: 'Usuário',
            representerChoose: 'Escolha do representante',
            lineManager: 'Gerente de linha',
            color: 'Cor',
            percent: 'Percentual',
            numberItem: 'Número Item',
            compkit: 'CompKit',
            controlled: 'Controlado',
            agreement100: 'Convênio 100',
            etrade: 'ETrade',
            ipi: 'IPI',
            kit: 'Kit',
            limited: 'Limitado',
            multiply: 'Múltiplo',
            ipiValue: 'Valor IPI',
            number: 'Número',
            icmsCredit: 'Crédito ICMS',
            minOrder: 'Pedido mínimo',
            branchCode: 'Código da Filial',
            campainCode: 'Código da Campanha',
            status: 'Status',
            PENDENTE: 'PENDENTE',
            APROVADO: "APROVADO",
            RECUSADO: 'RECUSADO',
            REPROVADO: 'REPROVADO',
            RASCUNHO: 'RASCUNHO',
            PROGRAMADO: "PROGRAMADO",
            INTEGRADO: 'INTEGRADO',
            FATURADO: 'FATURADO',
            NAO_FATURADO: 'NÃO FATURADO',
            ENTREGUE: 'ENTREGUE',
            email: 'Email',
            promoter: 'Promotor',
            neighborhood: 'Bairro',
            distributor: 'Distribuidor',
            itemRequired: "Número do item é obrigatório",
            S1: "Vendas",
            V2: "Bonificação",
            DESCONTOS_FINANCEIROS: "Descontos financeiros",
        },
        filters: {
            all: 'Todos',
            cnpj: 'CNPJ',
            name: 'Nome',
            clientCode: 'Código do Cliente',
            clientCod: 'Código Cliente',
            client: 'Cliente',
            clientName: 'Nome Cliente',
            status: 'Status',
            city: 'Cidade',
            state: 'Estado',
            type: 'Tipo',
            solicitation: 'Solicitação',
            date: 'Data',
            division: 'Divisão',
            order: 'Pedido',
            totalAmount: 'Valor Total',
            orderType: 'Tipo Pedido',
            protocol: 'Protocolo',
            bonusOrder: 'Pedido da Bonificação',
            bonusProtocol: 'Protocolo da Bonificação',
            orderDate: 'Data do pedido',
            bonusTotalAmount: 'Valor Total da Bonificação',
            user: 'Usuário',
            saleOrder: 'Pedido da Venda',
            saleProtocol: 'Protocolo da Venda',
            saleTotalAmount: 'Valor Total da Venda',
            transport: 'Transporte',
            code: 'Código',
            discount: 'Desconto',
            fixBonification: 'Bonificação fixa',
            proportionalBonification: 'Bonificação proporcional',
            notification: 'Notificação',
        },
        comparison: {
            equalTo: 'Igual à',
            diferentThen: 'Diferente de',
            biggerThen: 'Maior que',
            lessThen: 'Menor que',
            contains: 'Contém',
            noContains: 'Não contém',
            startWith: 'Inicia com',
        },
        months: {
            jan: 'Janeiro',
            feb: 'Fevereiro',
            mar: 'Março',
            abr: 'Abril',
            mai: 'Maio',
            jun: 'Junho',
            jul: 'Julho',
            aug: 'Agosto',
            sep: 'Setembro',
            oct: 'Outubro',
            nov: 'Novembro',
            dec: 'Dezembro',
        },
        reasons: {
            temporaryAbsent: 'Ausência Temporária',
            vacation: 'Férias',
            maternityLeave: 'Licença Maternidade',
            medicalLeave: 'Licença Médica',
            other: 'Outros',
            travel: 'Viagem',
        },
    },
};
